import React from 'react';
import { Element } from 'react-scroll';
import './Services.css';

const Services = () => {
  return (
    <Element name="services">
      <section className="services" id="services">
        <div className="services-content">
          <h2>Our Services</h2>
          <div className="services-list">
            <div className="service-item">
              <h3>High-Intensity Training</h3>
              <p>Push your limits with our high-intensity training programs.</p>
            </div>
            <div className="service-item">
              <h3>Hypertrophy Training</h3>
              <p>Build muscle mass with our specialized hypertrophy training.</p>
            </div>
            <div className="service-item">
              <h3>Powerlifting</h3>
              <p>Increase your strength with our powerlifting coaching.</p>
            </div>
            <div className="service-item">
              <h3>Sports Specialized Training</h3>
              <p>Become a better athlete with our training.</p>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default Services;
