import React from 'react';
import { Element } from 'react-scroll';
import './Testimonials.css';

const Testimonials = () => {
  return (
    <Element name="testimonials">
      <section className="testimonials" id="testimonials">
        <div className="testimonials-content">
          <h2>What Our Clients Say</h2>
          <div className="testimonial-item">
            <p>"Austin helped me achieve my fitness goals. Highly recommended!"</p>
            <p>- Elizabeth R.</p>
          </div>
          <div className="testimonial-item">
            <p>"The best training experience I've ever had."</p>
            <p>- Jeremy C.</p>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default Testimonials;
