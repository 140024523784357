import React from 'react';
import { Element } from 'react-scroll';
import './Contact.css';

const Contact = () => {
  const googleFormUrl = 'https://forms.gle/zZHrZGDFrTXH2TE38'; // Replace with your actual Google Form URL

  return (
    <Element name="contact">
      <section className="contact" id="contact">
        <div className="contact-content">
          <h2>Contact Us</h2>
          <p>If you would to schedule a training session please visit the link below.</p>
          <a href={googleFormUrl} target="_blank" rel="noopener noreferrer" className="contact-button">
            Go to Contact Form
          </a>
        </div>
      </section>
    </Element>
  );
};

export default Contact;
