import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero" id="home">
      <video className="hero-video" autoPlay loop muted>
        <source src={`${process.env.PUBLIC_URL}/img/hero2.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-content">
        <h1>Welcome to APex Performance</h1>
        <p>The pinnacle of fitness training in Utah.</p>
        <a href="https://forms.gle/zZHrZGDFrTXH2TE38">
          <button className="cta-button">Get Started</button>
        </a>
      </div>
      <div className="hero-credit">
        <a href="https://www.nonbasic.org/" target="_blank" rel="noopener noreferrer">
          FIlmed By @SinCitySweetHeart
        </a>
      </div>
    </section>
  );
};

export default Hero;
