import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">APex Performance</div>
      <button className="navbar-toggler" onClick={toggleMenu}>
        &#9776;
      </button>
      <ul className={`navbar-nav ${isOpen ? 'navbar-nav-open' : ''}`}>
        <li className="nav-item">
          <Link to="home" smooth={true} duration={500} onClick={() => setIsOpen(false)}>Home</Link>
        </li>
        <li className="nav-item">
          <Link to="about" smooth={true} duration={500} onClick={() => setIsOpen(false)}>About</Link>
        </li>
        <li className="nav-item">
          <Link to="services" smooth={true} duration={500} onClick={() => setIsOpen(false)}>Services</Link>
        </li>
        <li className="nav-item">
          <Link to="testimonials" smooth={true} duration={500} onClick={() => setIsOpen(false)}>Testimonials</Link>
        </li>
        <li className="nav-item">
          <Link to="contact" smooth={true} duration={500} onClick={() => setIsOpen(false)}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
