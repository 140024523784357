import React from 'react';
import './GymHighlight.css';

const gymImages = [
  { src: process.env.PUBLIC_URL + '/img/hardbodiez-1.jpg', alt: 'Hardbodiez Gym 1' },
  { src: process.env.PUBLIC_URL + '/img/hardbodiez-2.jpg', alt: 'Hardbodiez Gym 2' }
];

const GymHighlight = () => {
  console.log(gymImages); // Log the image paths to ensure they're correct

  return (
    <section className="gym-highlight">
      <h2>Exclusively at Hardbodiez</h2>
      <div className="gym-images">
        {gymImages.map((image, index) => (
          <img key={index} src={image.src} alt={image.alt} onError={(e) => console.error('Error loading image:', e.target.src)} />
        ))}
      </div>
    </section>
  );
};

export default GymHighlight;
