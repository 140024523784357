import React from 'react';
import { Element } from 'react-scroll';
import './About.css';

const mediaItems = [
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/arnold-dead.png`, alt: '775 Deadlift' },
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/bb.jpg`, alt: 'Image 2' },
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/bench-wrpf.jpg`, alt: 'Image 3' },
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/FrontLat.jpg`, alt: 'Front Lat' },
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/helping-dead.jpg`, alt: 'Helping Dead' },
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/team.jpg`, alt: 'Team' },
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/wrapping-knees.png`, alt: 'Wrapping Knees' }
];

const featuredItems = [
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/featured/reach-supplements.jpg`, alt: 'Sponsored by Reach Supplements', title: 'Sponsored by Reach Supplements' },
  { type: 'image', src: `${process.env.PUBLIC_URL}/img/main-about-img/featured/billboard.jpg`, alt: 'Featured on Billboard', title: 'Featured on Billboard' },
  { type: 'video', src: `${process.env.PUBLIC_URL}/img/main-about-img/featured/zero-fatalities.mp4`, alt: 'Zero Fatalities Commercial', title: 'Zero Fatalities Commercial' }
];

const About = () => {
  return (
    <Element name="about">
      <section className="about" id="about">
        <div className="about-content">
          <h2>About</h2>
          <p>Austin Patkos: Pro Powerlifer / Champion Bodybuilder</p>
          <p>Dedicated to transforming your body through personalized training programs.</p>
          <p>
            Austin Patkos is Utah's premiere strength trainer. He's achieved multiple pro totals, including a 1975 RAW total at The Arnold Fitness Expo in 2023.
            His best squat is 695, best competition bench is 505, and best competition deadlift is 775. In addition to his strength feats, he is also a certified WRPF Official and Judge. 
            Outside of powerlifting, he is also a champion bodybuilder. He has learned and trained under the best coaches the world has to offer, including spending time training under the Godfather of Powerlifting, Louie Simmons.
          </p>
          <div className="media-grid">
            {mediaItems.map((item, index) => (
              <div className="media-item" key={index}>
                {item.type === 'image' ? (
                  <img src={item.src} alt={item.alt} />
                ) : (
                  <video controls>
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
          <div className="featured-grid">
            {featuredItems.map((item, index) => (
              <div className="featured-item" key={index}>
                <h3 className="featured-title">{item.title}</h3>
                {item.type === 'image' ? (
                  <img src={item.src} alt={item.alt} />
                ) : item.title === 'Zero Fatalities Commercial' ? (
                  <video autoPlay loop muted>
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <video controls>
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </Element>
  );
};

export default About;